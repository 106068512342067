import React from "react";
import { NextSeo,BreadcrumbJsonLd  } from "next-seo";

const NextHelmet = (props) => {
  return (
    <NextSeo
      defaultTitle="Carefindr"
      title={props.title ? `Carefindr | ${props.title}` : "Carefindr"}
      description={props.description ? props.description : "Carefindr"}
      additionalMetaTags={[
        {
          property: "keywords",
          content: "Carefindr",
        },
      ]}
    />
  );
};

export default React.memo(NextHelmet);